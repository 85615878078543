import { AxiosResponse } from 'axios';
import camelCase from 'lodash-es/camelCase';

import { transformObjectKeys } from './casing';

export type Pagination = {
  total: number;
  perPage: number;
};

export const extractPagination = (response: AxiosResponse) => {
  if (response.headers.total && response.headers['per-page']) {
    return {
      ...response,
      data: transformObjectKeys(camelCase, {
        pagination: {
          total: parseInt(response.headers.total, 10),
          perPage: parseInt(response.headers['per-page'], 10),
        },
        data: response.data,
      }),
    };
  }
  return { ...response, data: transformObjectKeys(camelCase, response.data) };
};
