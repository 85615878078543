import React, { useState } from 'react';

import { ChevronDownIcon } from '@lightspeed/golf-b2c-design-system';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { t } from '@transifex/native';
import { useT } from '@transifex/react';

import { notNull } from 'src/utils/typings';
import { buildAlternateUrl } from 'src/utils/urls';

type MenuItem = {
  name: string;
  href: string;
};

const alternates = [
  {
    name: t('Canada (English)'),
    tld: 'ca',
  },
  {
    name: t('Canada (French)'),
    tld: 'ca',
    tldPath: 'fr',
  },
  {
    name: t('Ireland (English)'),
    tld: 'ie',
  },
  {
    name: t('France (French)'),
    tld: 'fr',
  },
  {
    name: t('Italy (Italian)'),
    tld: 'it',
  },
  {
    name: t('Spain (Spanish)'),
    tld: 'es',
  },
  {
    name: t('Netherlands (Dutch)'),
    tld: 'co.nl',
  },
  {
    name: t('Germany (German)'),
    tld: 'de',
  },
  {
    name: t('Morocco (English)'),
    tld: 'ma',
  },
  {
    name: t('Morocco (French)'),
    tld: 'ma',
    tldPath: 'fr',
  },
];

type LocaleSwitcherProps = {
  pageUrl?: string;
};

export const LocaleSwitcher = ({ pageUrl }: LocaleSwitcherProps) => {
  const menuItems: MenuItem[] = alternates
    .map((i) => {
      const href = buildAlternateUrl(pageUrl, i.tld, i.tldPath);
      if (typeof href !== 'undefined') {
        return {
          name: i.name,
          href,
        };
      }
      return null;
    })
    .filter(notNull);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useT();

  return (
    <DropdownMenu.Root modal={false} onOpenChange={setIsOpen}>
      <DropdownMenu.Trigger asChild>
        <button
          type="button"
          className={`flex w-full items-center justify-between rounded-md border border-grey-400 p-4 text-base hover:bg-grey-100 focus:bg-grey-100 active:bg-grey-300 active:outline-none ${
            isOpen ? 'bg-grey-100' : ''
          }`}
        >
          <span className="mr-3 font-bold">{t('United States (English)')}</span>
          <ChevronDownIcon />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="z-50 min-w-[var(--radix-dropdown-menu-trigger-width)] rounded-sm border border-grey-100 bg-white py-2 shadow-3"
          sideOffset={0}
          loop={true}
          align="start"
          side="top"
        >
          {menuItems.map((item) => (
            <DropdownMenu.Item asChild={true} key={item.name}>
              <a
                href={item.href}
                className="relative flex cursor-pointer items-center space-x-3 px-6 py-2 text-base text-black focus:bg-grey-100 focus:outline-none lg:px-4"
              >
                <span>{item.name}</span>
              </a>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
