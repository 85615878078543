import { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useSessionContext } from 'src/contexts/session';

import { getLinks } from './navigation.constants';
import { ResponsiveMenu } from './ResponsiveMenu';

const ResponsiveSearchModal = dynamic(
  () => import('./ResponsiveSearchModal').then((m) => m.ResponsiveSearchModal),
  { ssr: false }
);

const MyAccountMenu = dynamic(() => import('./MyAccountMenu').then((m) => m.MyAccountMenu), {
  ssr: false,
});

const greyLinksClassName =
  'flex items-center space-x-2 rounded-md border border-white bg-white px-4 py-3 text-base hover:bg-grey-100 focus:bg-grey-100 active:border-grey-400 active:bg-grey-100 active:outline-none';
const blackLinksClassName = `${greyLinksClassName} text-black font-bold`;

function Header() {
  const router = useRouter();

  const session = useSessionContext();
  const [returnUrl, setReturnUrl] = useState<string>(process.env.MONOLITH_BASE_URL || '');

  const [responsiveMenuIsOpen, setResponsiveMenuIsOpen] = useState(false);
  const [responsiveSearchIsOpen, setResponsiveSearchIsOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setReturnUrl(encodeURIComponent(window.location.href));
    }
  }, [router.asPath]);

  return (
    <header className="relative z-30 flex items-stretch justify-between gap-1 bg-white lg:px-8">
      <div className={`${responsiveSearchIsOpen ? 'invisible' : ''} flex items-stretch lg:hidden`}>
        <ResponsiveMenu
          onStateChange={(state: boolean) => setResponsiveMenuIsOpen(state)}
          returnUrl={returnUrl}
        />
      </div>
      <nav data-gtm-id="desktop-menu" className="flex items-center py-3 md:py-4 lg:py-2">
        <Link href="/" className="md:hidden">
          <Image
            src="/assets/logos/chronogolf-by-ls-stacked.svg"
            alt="Chronogolf by Lightspeed logo stacked"
            width={149}
            height={37}
            className=""
            priority={true}
          />
        </Link>
        <Link href="/" className="hidden md:block">
          <Image
            src="/assets/logos/chronogolf-by-ls.svg"
            alt="Chronogolf by Lightspeed logo"
            width={224}
            height={28}
            priority={true}
          />
        </Link>
        <div className="ml-6 hidden items-center space-x-2 lg:flex">
          <a
            className={greyLinksClassName}
            href={getLinks().deals.href}
            data-gtm-id={getLinks().deals.gtmId}
          >
            {getLinks().deals.icon}
            <span>{getLinks().deals.name}</span>
          </a>
          <a
            className={greyLinksClassName}
            href={getLinks().courses.href}
            data-gtm-id={getLinks().courses.gtmId}
          >
            {getLinks().courses.icon}
            <span>{getLinks().courses.name}</span>
          </a>
        </div>
      </nav>
      <nav
        data-gtm-id="desktop-menu"
        className="ml-8 hidden space-x-2 py-3 md:py-4 lg:flex lg:py-2"
      >
        <a className={greyLinksClassName} href={getLinks().b2b.href}>
          {getLinks().b2b.icon}
          <span>{getLinks().b2b.name}</span>
        </a>
        {!session && (
          <>
            <a
              className={blackLinksClassName}
              href={`${getLinks().signUp.href}${`?returnUrl=${returnUrl}`}`}
            >
              <span>{getLinks().signUp.name}</span>
            </a>
            <a
              className={blackLinksClassName}
              href={`${getLinks().logIn.href}${`?returnUrl=${returnUrl}`}`}
            >
              <span>{getLinks().logIn.name}</span>
            </a>
          </>
        )}
        {!!session && <MyAccountMenu session={session} />}
      </nav>
      <div className={`${responsiveMenuIsOpen ? 'invisible' : ''} flex items-stretch lg:hidden`}>
        <ResponsiveSearchModal
          onStateChange={(state: boolean) => setResponsiveSearchIsOpen(state)}
        />
      </div>
    </header>
  );
}

export default Header;
