import {
  DealIcon,
  TeetimeIcon,
  MembershipIcon,
  HouseAccountIcon,
  FamilyAccountIcon,
  ScoreIcon,
  SettingsIcon,
  CreditBookIcon,
  GiftCardIcon,
  PackageIcon,
  PlaceSolidIcon,
  BusinessIcon,
  TicketIcon,
} from '@lightspeed/golf-b2c-design-system';
import { t } from '@transifex/native';

// Needs to be a function to compute translation once tx client is loaded
export const getLinks = () => ({
  deals: {
    name: t('Deals'),
    href: `${process.env.NEXT_BASE_URL}/deals/`,
    icon: <DealIcon width={18} height={18} />,
    gtmId: 'nav-link-deals',
  },
  courses: {
    name: t('Golf destinations'),
    href: `${process.env.NEXT_BASE_URL}/locations`,
    icon: <PlaceSolidIcon width={18} height={18} />,
    gtmId: 'nav-link-search',
  },
  b2b: {
    name: t('List your golf course'),
    href: 'https://www.lightspeedhq.com/golf',
    icon: <BusinessIcon width={18} height={18} />,
  },
  signUp: {
    name: t('Sign up'),
    href: `${process.env.MONOLITH_BASE_URL}/register`,
  },
  logIn: {
    name: t('Log in'),
    href: `${process.env.MONOLITH_BASE_URL}/login`,
  },
  bookings: {
    name: t('Bookings'),
    href: `${process.env.MONOLITH_BASE_URL}/dashboard/#/reservations`,
    icon: <TeetimeIcon />,
  },
  memberships: {
    name: t('Memberships'),
    href: `${process.env.MONOLITH_BASE_URL}/dashboard/#/memberships`,
    icon: <MembershipIcon />,
  },
  houseAccounts: {
    name: t('House accounts'),
    href: `${process.env.MONOLITH_BASE_URL}/dashboard/#/house-accounts`,
    icon: <HouseAccountIcon />,
  },
  familyAccounts: {
    name: t('Family accounts'),
    href: `${process.env.MONOLITH_BASE_URL}/dashboard/#/family-accounts`,
    icon: <FamilyAccountIcon />,
  },
  creditsBook: {
    name: t('Credit books'),
    href: `${process.env.MONOLITH_BASE_URL}/dashboard/#/credit-books`,
    icon: <CreditBookIcon />,
  },
  loyaltyPoints: {
    name: t('Loyalty points'),
    href: `${process.env.MONOLITH_BASE_URL}/dashboard/#/loyalty-points`,
    icon: <GiftCardIcon />,
  },
  scoringFactor: {
    name: t('Scoring factor'),
    href: `${process.env.MONOLITH_BASE_URL}/dashboard/#/scores`,
    icon: <ScoreIcon />,
  },
  registrations: {
    name: t('Registrations'),
    href: `${process.env.MONOLITH_BASE_URL}/dashboard/#/orders`,
    icon: <TicketIcon />,
  },
  packages: {
    name: t('Packages'),
    href: `${process.env.MONOLITH_BASE_URL}/dashboard/#/packages`,
    icon: <PackageIcon />,
  },
  settings: {
    name: t('Settings'),
    href: `${process.env.MONOLITH_BASE_URL}/dashboard/#/settings/profile`,
    icon: <SettingsIcon />,
  },
  logOut: {
    name: t('Log out'),
    href: `${process.env.MONOLITH_BASE_URL}/logout`,
  },
});
