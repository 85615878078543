const supportedTlds = ['.com', '.ca', '.fr', '.es', '.it', '.ie', '.de', '.co.nl', '.ma'];

type GetPageUrlProps = {
  host?: string;
  path: string;
};

export const getPageUrl = ({ host, path }: GetPageUrlProps): string => {
  if (host) {
    return host?.includes('chronodev') || host?.includes('localhost')
      ? `http://${host}${path}`
      : `https://${host}${path}`;
  }
  return process.env.MONOLITH_BASE_URL + path;
};

export const buildAlternateUrl = (
  pageUrl: string | undefined,
  newTld: string,
  newTldPath?: string
) => {
  if (pageUrl) {
    const { protocol, hostname, port, pathname, search } = new URL(pageUrl);
    const tld = supportedTlds.find((tld) => hostname.includes(tld));
    let updatedHostname = hostname;
    if (tld) {
      updatedHostname = hostname.replace(tld, `.${newTld}`);
    }
    return `${protocol}//${updatedHostname}${port ? `:${port}` : ''}${
      newTldPath ? `/${newTldPath}` : ''
    }${pathname}${search}`;
  }
  return undefined;
};

export const urlEncode = (str: string) => {
  return encodeURI(
    str
      .toString()
      .replace(/-+/g, '~') // Replace dashes with ~
      .replace(/\s*,\s*|\s+,/g, '--') // Replace comma spaces with --
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  );
};

export const urlDecode = (str: string | string[] | undefined) => {
  if (!str) return '';
  return decodeURI(
    str
      .toString()
      .replace(/--+/g, ', ') // Replace double dashes with `, `
      .replace(/-+/g, ' ') // Replace dash with space
      .replace(/~+/g, '-') // Replace tildes with -
  );
};
