import { useT } from '@transifex/react';

export default function Legal() {
  const t = useT();

  return (
    <>
      <h4 className="mb-4 heading-2xs">{t('Legal')}</h4>
      <ul className="flex flex-col space-y-3 text-sm text-grey-600">
        <li>
          <a
            href="https://www.lightspeedhq.com/legal/privacy-statement-for-consumers/"
            className="hover:underline"
          >
            {t('Privacy Policy')}
          </a>
        </li>
        <li>
          <a
            href={`${process.env.MONOLITH_BASE_URL}/page/cookie_policy/`}
            className="hover:underline"
          >
            {t('Cookie Policy')}
          </a>
        </li>
        <li>
          <a
            href={`${process.env.MONOLITH_BASE_URL}/page/terms_and_conditions/`}
            className="hover:underline"
          >
            {t('Terms & Conditions')}
          </a>
        </li>
      </ul>
    </>
  );
}
