import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from '@lightspeed/golf-b2c-design-system';
import { useT } from '@transifex/react';

export default function Social() {
  const t = useT();

  return (
    <div className="flex items-center justify-center space-x-6">
      <a data-cy="social-link-facebook" href="https://www.facebook.com/LightspeedHQ">
        <FacebookIcon width={20} height={20} />
        <span className="sr-only">{t('Follow on us Facebook')}</span>
      </a>
      <a data-cy="social-link-twitter" href="https://twitter.com/LightspeedHQ">
        <TwitterIcon width={20} height={20} />
        <span className="sr-only">{t('Follow on us Twitter')}</span>
      </a>
      <a data-cy="social-link-instagram" href="https://www.instagram.com/lightspeedhq">
        <InstagramIcon width={20} height={20} />
        <span className="sr-only">{t('Follow on us Instagram')}</span>
      </a>
      <a data-cy="social-link-linkedin" href="https://www.linkedin.com/company/lightspeed-hq">
        <LinkedinIcon width={20} height={20} />
        <span className="sr-only">{t('Follow on us LinkedIn')}</span>
      </a>
    </div>
  );
}
