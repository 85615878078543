import axios from 'axios';

export enum MEMBERTYPE {
  MEMBER = 'member',
  PUBLIC = 'public',
}

export type Affiliation = {
  clubId: string;
  affiliationTypes: {
    id: number;
    role: MEMBERTYPE;
    bookingRange: number;
  }[];
};

export type Session = {
  uuid: string;
  affiliations: Affiliation[];
  features?: {
    creditBooks: boolean;
    familyAccounts: boolean;
    houseAccounts: boolean;
    loyaltyPoints: boolean;
    bookingFlowV2: boolean;
  };
};

export function getSession(): Promise<Session> {
  return axios
    .get(`${process.env.MONOLITH_BASE_URL}/marketplace/v2/session`)
    .then((response) => response.data);
}
