/* eslint-disable no-console */
// We only allow console usage in here to avoid having it clutter the codebase

export async function reportError(error: Error) {
  const reportClient = await getReportClient();
  reportClient.reportError(error);
}

async function getReportClient(): Promise<{ reportError: (error: Error) => void }> {
  // Server side reporting logs to console, then Datadog pick it up
  if (typeof window === 'undefined') return { reportError: console.error };

  // Client side reporting tries to find Datadog Run client or fallback to console
  const { datadogRum } = await import('@datadog/browser-rum');
  try {
    const ddConfig = datadogRum.getInitConfiguration();
    if (typeof ddConfig === 'undefined' || !ddConfig.service)
      throw new Error('Cannot find DataDog');
    return { reportError: datadogRum.addError };
  } catch (e) {
    return { reportError: console.error };
  }
}
