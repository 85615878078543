export function notNull<T>(value: T | null): value is T {
  return value !== null;
}

// This type allow to find the type within another one
// ex: element in a collection or the content of a promise
// Based on https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html#type-inference-in-conditional-types
export type Unpacked<T> = T extends (infer U)[]
  ? U
  : T extends (...args: any[]) => infer U
    ? U
    : T extends Promise<infer U>
      ? U
      : T;
