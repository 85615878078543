//
// THIS IS A COPY OF TRANSIFEX INTERNAL CLASS
// TODO, wait for Transifex answer about exposing it and create a PR?
// https://community.transifex.com/t/transifex-native-usage-with-nextjs-ssr-ssg-and-react-server-components/3183
//

export default class MemoryCache {
  // eslint-disable-next-line no-empty-function
  constructor(public translationsByLocale: Record<string, Record<string, string>> = {}) {}

  /**
   * Store locale translations in cache
   *
   * @param {String} localeCode
   * @param {Object} translations - Object with translation key:value pairs
   * @param {String} translations[key] - Translation string
   */
  update(localeCode: string, translations: Record<string, string>) {
    const prevTranslations = this.translationsByLocale[localeCode] || {};
    this.translationsByLocale[localeCode] = {
      ...prevTranslations,
      ...translations,
    };
  }

  /**
   * Get translations by locale from cache
   *
   * @param {String} localeCode
   * @returns {Object} translations
   * @returns {String} translations[key]
   */
  getTranslations(localeCode: string) {
    return this.translationsByLocale[localeCode] || {};
  }

  /**
   * Check if locale has translations in cache
   *
   * @param {String} localeCode
   * @returns {Boolean}
   */
  hasTranslations(localeCode: string) {
    return !!this.translationsByLocale[localeCode];
  }

  /**
   * Check if translations are stale and need refreshing
   *
   * @param {String} localeCode
   * @returns {Boolean}
   */
  isStale(localeCode: string) {
    return !this.hasTranslations(localeCode);
  }

  /**
   * Get translation by key. If key does not exist in cache,
   * return empty string
   *
   * @param {String} key
   * @param {String} localeCode
   * @returns {String} - translation or empty string
   */
  get(key: string, localeCode: string) {
    return this.getTranslations(localeCode)[key] || '';
  }
}
