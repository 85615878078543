import React, { useEffect, useRef, useState } from 'react';

import { CloseIcon, MenuIcon } from '@lightspeed/golf-b2c-design-system';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useT } from '@transifex/react';

import { Session } from 'src/api/session';
import { useSessionContext } from 'src/contexts/session';
import { txTranslate } from 'src/utils/i18n';

import {
  defaultSection,
  b2bSection,
  loggedOutSection,
  loggedInSection,
  ItemsSection,
} from './navigation.helpers';

interface ResponsiveMenuProps {
  returnUrl: string;
  onStateChange: (state: boolean) => void;
}

const buildMenuItem = (t: txTranslate, returnUrl: string, session?: Session) => {
  const menuTree = [defaultSection(), b2bSection()];
  if (!session) menuTree.push(loggedOutSectionWithReturnUrl(returnUrl, loggedOutSection()));
  if (session) menuTree.push({ title: t('My account'), ...loggedInSection(session.features) });
  return menuTree;
};

const loggedOutSectionWithReturnUrl = (returnUrl: string, section: ItemsSection) => {
  return {
    ...section,
    items: section.items.map((item) => ({
      ...item,
      href: `${item.href}?returnUrl=${returnUrl}`,
    })),
  };
};

export const ResponsiveMenu = ({ onStateChange, returnUrl }: ResponsiveMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onOpenChange = (open: boolean) => {
    onStateChange(open);
    setIsOpen(open);
  };

  const t = useT();
  const session = useSessionContext();

  // Menu content
  const [menuTree, setMenuTree] = useState<ItemsSection[]>(buildMenuItem(t, returnUrl, session));
  useEffect(() => {
    setMenuTree(buildMenuItem(t, returnUrl, session));
  }, [session, t, returnUrl]);

  // Small hacks for our responsive menu canvas height until dvh unit become ok to use.
  // 60 is the header's height in responsive
  // Later, this whole piece could be replaced with the utility class `h-[calc(100dvh-60px)]`
  const windowHeight = useRef<number>();

  const updateResponsiveMenuHeight = () => {
    windowHeight.current = window.innerHeight - 60;
    document.documentElement.style.setProperty(
      '--responsive-menu-height',
      `${windowHeight.current}px`
    );
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => updateResponsiveMenuHeight());
      updateResponsiveMenuHeight();
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', () => updateResponsiveMenuHeight());
      }
    };
  }, []);

  return (
    <DropdownMenu.Root modal={true} onOpenChange={onOpenChange}>
      <DropdownMenu.Trigger asChild>
        <button
          type="button"
          className="flex grow items-center justify-center px-4"
          aria-label={t('Toggle main navigation')}
        >
          {!isOpen && <MenuIcon width={24} height={24} />}
          {isOpen && <CloseIcon width={24} height={24} />}
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <>
          <DropdownMenu.Content
            className="relative z-30 -mt-1 max-h-[var(--responsive-menu-height)] w-screen overflow-auto bg-white"
            sideOffset={0}
            loop={true}
          >
            {menuTree.map((section, index) => (
              <React.Fragment key={section.key}>
                {section.title && (
                  <DropdownMenu.Label className="mx-1 mt-2 block py-2 pl-3 pr-9 text-grey-500 heading-2xs">
                    {section.title}
                  </DropdownMenu.Label>
                )}
                {section.items &&
                  section.items.map((item) => (
                    <DropdownMenu.Item asChild={true} key={item.name}>
                      <a
                        href={item.href}
                        target={item.target}
                        className={`relative flex cursor-pointer items-center space-x-3 px-6 py-4 text-base text-black focus:bg-grey-100 focus:outline-none lg:px-4 ${item.className}`}
                      >
                        {item.icon}
                        <span>{item.name}</span>
                      </a>
                    </DropdownMenu.Item>
                  ))}
                {index !== menuTree.length - 1 && (
                  <DropdownMenu.Separator className="mx-2 my-2 border-t border-grey-200" />
                )}
              </React.Fragment>
            ))}
          </DropdownMenu.Content>
          <div className="fixed inset-0 z-20 bg-black opacity-50" />
        </>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
