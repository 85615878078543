import { ExternalLinkIcon } from '@lightspeed/golf-b2c-design-system';
import { useT } from '@transifex/react';
import Image from 'next/image';

import AppStoreBadge from 'src/components/AppStoreBadge';
import PlayStoreBadge from 'src/components/PlayStoreBadge';

import AdCenter from './AdCenter';
import Company from './Company';
import Legal from './Legal';
import { LocaleSwitcher } from './LocaleSwitcher';
import Social from './Social';

type FooterProps = {
  pageUrl?: string;
};

export default function Footer({ pageUrl }: FooterProps) {
  const t = useT();

  return (
    <footer className="z-10 border-t border-solid border-grey-200 lg:px-6">
      <div className="xl:container">
        <div className="flex flex-col space-y-10 py-10 pb-0 lg:flex-row lg:space-x-6 lg:space-y-0 lg:pb-10">
          <div className="flex flex-col space-y-8 px-4 md:mx-auto md:w-[70%] lg:w-[30%] lg:justify-between lg:px-0">
            <div>
              <LocaleSwitcher pageUrl={pageUrl} />
            </div>
            <div>
              <h4 className="mb-4 heading-2xs">{t('Download Chronogolf app')}</h4>
              <div className="flex space-x-4">
                <AppStoreBadge campaign="marketplace_footer" />
                <PlayStoreBadge campaign="marketplace_footer" />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-10 px-4 md:mx-auto md:w-[70%] lg:w-[40%] lg:items-start lg:justify-between lg:px-0">
            <nav className="flex w-full gap-8">
              <div className="w-1/3">
                <Company />
              </div>
              <div className="w-1/3">
                <Legal />
              </div>
              <div className="w-1/3">
                <AdCenter />
              </div>
            </nav>
            <div>
              <Social />
            </div>
          </div>
          <div className="bg-grey-50 px-4 py-6 lg:mt-8 lg:w-[30%] lg:px-6 lg:py-8">
            <div className="md:mx-auto md:w-[70%] lg:w-full">
              <p className="leading-base mb-3 text-lg font-bold">
                {t('Interested in listing your course? Join the Chronogolf community today!')}
              </p>
              <a
                href="https://www.lightspeedhq.com/golf/"
                target="_blank"
                className="inline-flex items-center font-bold text-info hover:underline"
                rel="noreferrer"
              >
                {t('List my golf course')}
                <ExternalLinkIcon width={18} height={18} className="ml-2" />
              </a>
              <div className="mt-6 flex w-full items-center justify-end">
                <p className="mr-1 text-xs text-grey-600">{t('Powered by Lightspeed Golf')}</p>
                <Image
                  src="/assets/logos/lightspeed-logo.svg"
                  alt="Lightspeed Logo"
                  width="69"
                  height="16"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-6 text-grey-600 xl:container lg:mx-auto lg:border-t lg:border-grey-200 lg:px-0">
          <p className="text-xs">© Lightspeed Commerce, Inc.</p>
        </div>
      </div>
    </footer>
  );
}
