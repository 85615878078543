import { useRef } from 'react';

import { t, tx } from '@transifex/native';

import MemoryCache from './memory-cache';

//
// CONSTANTS
//

// We keep it hardcoded here for now as it is visible in the network calls to transifex anyway
const TX_MARKETPLACE_PUBLIC_TOKEN = '1/17078604770b4cd11cb68c63dd60c8500ce794ec';

export enum ISOLocale {
  'en-CA' = 'en-CA',
  'en-US' = 'en-US',
}

export enum TxLocale {
  'en_CA' = 'en_CA',
  'en_US' = 'en_US',
}

const localeMap = {
  [ISOLocale['en-CA']]: TxLocale.en_CA,
  [ISOLocale['en-US']]: TxLocale.en_US,
};

//
// TYPES
//

export type TranslationCache = Record<TxLocale, Record<string, string>>;
export type txProps = { cache?: TranslationCache; locale: TxLocale };
export type txTranslate = typeof t;

//
// FUNCTIONS
//

export function isLocaleDefined(locale?: string): asserts locale is string {
  if (!locale)
    throw new Error(
      'Locale is not defined, either make sure your context has a valid locale or that the next config declares a default one'
    );
}

export function isISOLocale(locale: string): asserts locale is ISOLocale {
  if (!Object.keys(ISOLocale).includes(locale)) throw new Error('Not a valid ISO Locale');
}

export async function initTx(
  contextLocale?: string,
  contextDefaultLocale?: string
): Promise<txProps> {
  const locale = contextLocale || contextDefaultLocale;

  isLocaleDefined(locale);
  isISOLocale(locale);

  const txLocale = localeMap[locale];
  tx.init({
    token: TX_MARKETPLACE_PUBLIC_TOKEN,
  });
  await tx.setCurrentLocale(txLocale);
  return {
    cache: tx.cache.translationsByLocale,
    locale: txLocale,
  };
}

//
// HOOKS
//

export function useTxWithCache({ locale, cache }: txProps = { locale: TxLocale.en_CA }): void {
  const txInitialized = useRef(false);

  if (!txInitialized.current) {
    tx.init({
      token: TX_MARKETPLACE_PUBLIC_TOKEN,
      cache: new MemoryCache(cache),
      currentLocale: locale,
    });
    txInitialized.current = true;
  }
}
