import { Session } from 'src/api/session';
import { notNull } from 'src/utils/typings';

import { getLinks } from './navigation.constants';

export type ItemsSection = {
  key: string;
  title?: string;
  items: MenuItem[];
};

export type MenuItem = {
  name: string;
  href: string;
  target?: string;
  className?: string;
  icon?: JSX.Element;
};

export const defaultSection = (): ItemsSection => {
  return {
    key: 'defaultSection',
    items: [getLinks().deals, getLinks().courses],
  };
};

export const b2bSection = (): ItemsSection => {
  return {
    key: 'b2bSection',
    items: [{ ...getLinks().b2b, target: '_blank' }],
  };
};

export const loggedOutSection = (): ItemsSection => {
  return {
    key: 'loggedOutSection',
    items: [
      { ...getLinks().signUp, className: 'font-bold text-black' },
      { ...getLinks().logIn, className: 'font-bold text-black' },
    ],
  };
};

export const loggedInSection = (features?: Session['features']): ItemsSection => {
  return {
    key: 'loggedInSection',
    items: [
      getLinks().bookings,
      getLinks().memberships,
      features?.houseAccounts ? getLinks().houseAccounts : null,
      features?.familyAccounts ? getLinks().familyAccounts : null,
      features?.creditBooks ? getLinks().creditsBook : null,
      features?.loyaltyPoints ? getLinks().loyaltyPoints : null,
      getLinks().scoringFactor,
      getLinks().registrations,
      getLinks().packages,
      getLinks().settings,
      { ...getLinks().logOut, className: 'font-bold text-danger' },
    ].filter(notNull),
  };
};
