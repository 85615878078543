import { useT } from '@transifex/react';

export default function AdCenter() {
  const t = useT();

  return (
    <>
      <h4 className="mb-4 heading-2xs">{t('Ad Center')}</h4>
      <ul className="flex flex-col space-y-3 text-sm text-grey-600">
        <li>
          <a
            id="ad-center-sign-up"
            target="_blank"
            href="https://auth-selfserve.m32connect.com/validation?publisher_storage_id=c258604f711327af29999b5b77fd4550&associated_publisher_id=001JR000007ZpZwYAK&home_page_url=https%3A%2F%lightspeed-selfserve.m32connect.com&language=en/"
            className="hover:underline"
          >
            {t('Sign up')}
          </a>
        </li>
        <li>
          <a
            id="ad-center-sign-in"
            target="_blank"
            href="https://prod-self-serve-backend.m32connect.com/login?publisher_storage_id=c258604f711327af29999b5b77fd4550&associated_publisher_id=001JR000007ZpZwYAK&home_page_url=https%3A%2F%2Flightspeed-selfserve.m32connect.com&lang=en"
            className="hover:underline"
          >
            {t('Sign In')}
          </a>
        </li>
      </ul>
    </>
  );
}
