import { Component } from 'react';

import { Button } from '@lightspeed/golf-b2c-design-system';
import { T } from '@transifex/react';
import Image from 'next/image';

import { reportError } from 'src/utils/reporting';

type ErrorBoundaryState = { error: Error | null };

const initialState: ErrorBoundaryState = { error: null };

class ErrorBoundary extends Component<
  React.PropsWithRef<React.PropsWithChildren>,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = initialState;
  }

  static getDerivedStateFromError(error: Error | null) {
    // Update state so the next render will show the fallback UI
    return { error };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: Error | null) {
    // You can use your own error logging service here
    if (error) reportError(error);
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <div className="relative mx-auto my-12 flex max-w-sm flex-col gap-8 px-4 lg:container md:max-w-lg lg:mt-12 lg:max-w-max lg:flex-row-reverse lg:items-center lg:gap-12 lg:px-6">
          <div className="relative block min-h-[200px] w-full lg:min-h-[500px] lg:w-1/2">
            <Image src="/assets/images/error-500.svg" alt="500" fill={true} />
          </div>
          <div className="flex w-full flex-col items-start gap-2 lg:w-1/2">
            <h1 className="text-xl font-bold lg:text-[2rem]">
              <T _str="Oops" />
            </h1>
            <h2 className="mb-4 text-4xl font-black leading-none lg:text-[4rem]">
              <T _str="Looks like you are in the water." />
            </h2>
            <p className="mb-4 text-lg">
              <T _str="We apologize for the inconvenience. Take a Mulligan and try your request again! We have been notified and are looking into the issue." />
            </p>
            <Button
              onPress={() => {
                this.setState({ error: null });
              }}
            >
              <T _str="Try again" />
            </Button>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
