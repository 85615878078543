import Image from 'next/image';

import { AppStoreUrls } from 'src/constants';

type PlayStoreBadgeProps = {
  campaign: string;
};

export default function PlayStoreBadge(props: PlayStoreBadgeProps) {
  const { campaign } = props;
  return (
    <a
      href={`${AppStoreUrls.ANDROID}&hl=en&utm_source=chronogolf&utm_campaign=${campaign}`}
      target="_blank"
      rel="noreferrer"
      data-cy="play-store-link"
    >
      <Image src="/assets/images/google_play_badge.svg" alt="Play Store" width="136" height="40" />
    </a>
  );
}
