import Image from 'next/image';

import { AppStoreUrls } from 'src/constants';

type AppStoreBadgeProps = {
  campaign: string;
};

export default function AppStoreBadge(props: AppStoreBadgeProps) {
  const { campaign } = props;
  return (
    <a
      href={`${AppStoreUrls.IOS}&ct=${campaign}`}
      target="_blank"
      rel="noreferrer"
      data-cy="app-store-link"
    >
      <Image src="/assets/images/app_store_badge.svg" alt="App Store" width="120" height="40" />
    </a>
  );
}
