import { isObject } from 'lodash-es';

export function transformObjectKeys(transformKeyFn: (s: string) => string, obj: any): any {
  if (!isObject(obj) || (typeof File !== 'undefined' && obj instanceof File)) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((value: any) => transformObjectKeys(transformKeyFn, value));
  }
  return Object.keys(obj).reduce((resultObj, key) => {
    return {
      ...resultObj,
      [transformKeyFn(key)]: transformObjectKeys(transformKeyFn, (obj as Record<string, any>)[key]),
    };
  }, {});
}
