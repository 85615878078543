import { createContext, useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { getSession, Session } from 'src/api/session';

const SessionContext = createContext<Session | undefined>(undefined);

export function SessionWrapper({ children }: { children: JSX.Element }) {
  const { data: session } = useQuery<Session, AxiosError>({
    queryKey: ['session'],
    queryFn: getSession,
    staleTime: Infinity,
  });
  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
}

export function useSessionContext() {
  return useContext(SessionContext);
}
