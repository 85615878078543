export enum AppStoreUrls {
  IOS = 'https://itunes.apple.com/ca/app/id1268751136?mt=8',
  ANDROID = 'https://play.google.com/store/apps/details?id=com.chronogolf.booking.chronogolf',
}

export enum OnetrustGroups {
  STRICTLY_NECESSARY_COOKIES = 'C0001',
  PERFORMANCE_COOKIES = 'C0002',
  // FUNCTIONAL_COOKIES = 'C0003', Not used for now
  TARGETING_COOKIES = 'C0004',
  // SOCIAL_MEDIA_COOKIES = 'C0005', Not used for now
}
