import { useT } from '@transifex/react';

import { openZenDeskWidget } from 'src/utils/zendesk';

export default function Company() {
  const t = useT();

  return (
    <>
      <h4 className="mb-4 heading-2xs">{t('Company')}</h4>
      <ul className="flex flex-col items-start space-y-3 text-sm text-grey-600">
        <li>
          <a href="https://www.lightspeedhq.com/golf/" className="hover:underline">
            {t('About us')}
          </a>
        </li>
        <li>
          <a href="https://www.lightspeedhq.com/careers/" className="hover:underline">
            {t('Careers')}
          </a>
        </li>
        <li>
          <button
            type="button"
            onClick={() => openZenDeskWidget()}
            data-cy="footer-contact-button"
            className="hover:underline"
          >
            {t('Contact')}
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => openZenDeskWidget()}
            data-cy="footer-help-button"
            className="hover:underline"
          >
            {t('Help')}
          </button>
        </li>
      </ul>
    </>
  );
}
